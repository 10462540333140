import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import "./App.css";
import Footer from "./sections/Footer";
import Logo from "./Logo";
import Navbar from "./sections/Navbar";
import Material from "./sections/Material";
// import Applications from "./sections/Applications";
import Machine from "./sections/Machine";
import { SmoothScroll } from "./common/SmoothScroll";
import Order from "./sections/Order";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Logo />
        <div
          className="block"
          style={{
            color: "white",
            textAlign: "center",
            marginTop: "2rem",
            font: "normal normal 100 1.5rem/2rem 'Montserrat', sans-serif",
          }}
        >
          <p>You only live once</p>
          <p>Your spirit lives forever</p>
          <p>
            Stay tuned{" "}
            <a
              href="https://twitter.com/digitalimortal"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white" }}
            >
              <FontAwesomeIcon icon={faTwitter} style={{ marginLeft: "0.5rem" }} />
            </a>
          </p>
        </div>
      </header>

      <Footer />
    </div>
  );
}

export default App;
